<template>
  <div id="edit_member">
    <div class="admin_main_block">
      <div class="user_temp_title">修改会员资料</div>
      <div class="admin_table_main">
        <div class="edit_password_from">
          <div class="item">
            <label>手机号：</label>
            <input
              type="text"
              disabled
              placeholder="手机号"
              v-model="formData.phone"
            />
          </div>
          <div class="item">
            <label>微&nbsp;&nbsp;&nbsp;&nbsp;信：</label>
            <input type="text" placeholder="微信" v-model="formData.wechat" />
          </div>
          <div class="item">
            <label>Q&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Q：</label>
            <input type="text" placeholder="QQ" v-model="formData.qq" />
          </div>
          <div class="item">
            <label>邮&nbsp;&nbsp;&nbsp;&nbsp;箱：</label>
            <input type="text" placeholder="邮箱" v-model="formData.email" />
          </div>
          <div class="btn" @click="update">确定修改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      loading: false,
      formData: {
        phone: "",
        wechat: "",
        qq: "",
        email: "",
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  watch: {},
  methods: {
    update() {
      if (this.loading) return;
      this.loading = true;
      const loading = this.$loading();
      this.formData.id = localStorage.getItem("ids");

      this.$request.post({
        url: "/ReportApi/business/user/modifyUserInfo",
        params: this.formData,
        success: (res) => {
          this.$message.success("修改成功");
          this.getUserInfo();
        },
        finally: () => {
          this.loading = false;
          loading.close();
        },
      });
    },

    getUserInfo() {
      this.$request.post({
        url: "/ReportApi/business/user/getUserInfo",
        params: {
          phone: localStorage.getItem("phone"),
        },
        success: (res) => {
          if (res) {
            var arr = {};
            arr.info = res;
            if (res.levelId == 0) {
              arr.level = "普通会员";
            } else if (res.levelId == 1) {
              arr.level = "高级会员";
            } else {
              arr.level = "最高会员";
            }
            this.formData = {
              phone: res.phone,
              wechat: res.wechat,
              qq: res.qq,
              email: res.email,
            };
            this.$store.commit("getUserInfo", arr);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#edit_member {
  .admin_main_block {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    min-height: 855px;
    border-radius: 5px;
    padding: 20px;
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .admin_table_main {
      .edit_password_from {
        margin-top: 40px;
        padding-bottom: 30px;
        padding-left: 70px;
        .item {
          line-height: 40px;
          margin-bottom: 30px;
          clear: both;
          label {
            margin-right: 10px;
            font-size: 14px;
          }
          input {
            width: 272px;
            height: 40px;
            padding: 0 10px;
            border: 1px solid #ddd;
            border-radius: 6px;
            box-sizing: border-box;
            outline: none;
          }
        }
        .btn {
          width: 270px;
          border: 0px;
          background-color: #ea242f;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
          color: #ffffff;
          font-size: 14px;
          cursor: pointer;
          margin-left: 68px;
        }
      }
    }
  }
}
</style>
